import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// CUSTOM HOOKS
import { useHelpers } from '../../hooks/useHelpers';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminReservations() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { handleReservationDelete, copyOnClipboard, handleReservationPayment } = useHelpers();
  // PAGINAZIONE
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);
  // DATA
  const [reservations, setReservations] = useState(null);

  useEffect(() => {
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, [globalRefresh]);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    // if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'reservations/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setReservations(res.data);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }
  
  return (
    <Container className="content admin">
      <Row className="page_title">
        <Col>
          <h1>Prenotazioni</h1>
        </Col>
      </Row>
      <Row>
        <Col id="list_filter">
          <div>
            <form className="custom_form" id="query_form">
              <input type="text" placeholder="Cerca prenotazione" id="query" value={query} onChange={(e) => { handleInputChange(e.target.value) }} />
              <DynamicIcon iconName='FaSearch' />
            </form>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='tab_wrapper'>
          { reservations ? 
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{'width':'50px'}}>Stato</th>
                    <th>ID</th>
                    <th style={{'width':'160px'}}>Data prenotazione</th>
                    <th>Codice</th>
                    <th>Nome</th>
                    {/* <th>Telefono</th> */}
                    <th>E-mail</th> 
                    <th>Tipo escursione</th>
                    <th style={{'width':'160px'}}>Data escursione</th>
                    <th style={{'width':'100px','textAlign':'center'}}>Importo</th>
                    <th style={{'width':'40px'}}><DynamicIcon iconName='GiSteeringWheel' size="24" /></th>
                    <th style={{'width':'40px'}}><DynamicIcon iconName='MdEventSeat' size="24" /></th>
                    <th style={{'width':'200px','textAlign':'center'}}>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  { reservations.length ? reservations.map((reservation) => {
                      return <tr key={ reservation.id } className={ reservation.stato == 'inprogress' ? 'inprogress' : '' }>
                              <td><span className={'tag '+reservation.stato}></span></td>
                              <td>{ reservation.id }</td>
                              <td>{ reservation.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.created_at.substr(11,5) : '-' }</td>
                              <td>{ reservation.codice.toUpperCase() }</td>
                              <td>{ reservation.titolare_nome && reservation.titolare_cognome ? reservation.titolare_nome+' '+reservation.titolare_cognome : '?' }</td>
                              {/* <td>{ reservation.titolare_telefono ? reservation.titolare_telefono : '?' }</td> */}
                              <td>{ reservation.titolare_email ? reservation.titolare_email : '?' }</td> 
                              <td>{ reservation.slot && reservation.slot.slot_type ? reservation.slot.slot_type.descrizione : '?' }</td>
                              <td>{ reservation.slot && reservation.slot.inizio ? reservation.slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+reservation.slot.inizio.substr(11,5) : '-' }</td>
                              <td style={{'textAlign':'right'}}>{ reservation.pagamento_importo ? parseInt(reservation.pagamento_importo)+' €' : '-' }</td>
                              <td style={{'textAlign':'center'}}>{ reservation.utenti ? reservation.utenti.guidatori : '-' }</td>
                              <td style={{'textAlign':'center'}}>{ reservation.utenti ? reservation.utenti.passeggeri : '-' }</td>
                              <td className="actions" style={{'textAlign':'right'}}>
                                { reservation.stato != 'inprogress' ?
                                  <>
                                    <Button className='reservation_det' onClick={() => { navigate('/admin/reservations/edit', { state: { reservation_id: reservation.id, page: page, query: query } }) }}>
                                      <DynamicIcon iconName='IoIosArrowForward' />
                                      <Tooltip anchorSelect=".reservation_det" place="top" variant="info">Vedi prenotazione</Tooltip>
                                    </Button>
                                    <Button className='reservation_link' onClick={() => { copyOnClipboard(window.BOOKING_URL+'/manage/'+btoa(reservation.titolare_email+'|'+reservation.codice), "Il link è stato copiato negli appunti") }}>
                                      <DynamicIcon iconName='FaLink' />
                                      <Tooltip anchorSelect=".reservation_link" place="top" variant="info">Copia link gestione</Tooltip>
                                    </Button>
                                    <Button className='reservation_pay' onClick={() => { handleReservationPayment(reservation) }}>
                                        <DynamicIcon iconName='IoLogoEuro' />
                                        <Tooltip anchorSelect=".reservation_pay" place="top" variant="info">Gestisci pagamento</Tooltip>
                                      </Button>
                                  </>
                                : '' }
                                <Button className='slot_det' onClick={() => { navigate('/admin/slots/view', { state: { slot_id: reservation.slot.id, page: page, query: query } }) }}>
                                  <DynamicIcon iconName='FaCalendarDay' />
                                  <Tooltip anchorSelect=".slot_det" place="top" variant="info">Vedi escursione</Tooltip>
                                </Button>
                                <Button className='reservation_del' variant="danger" onClick={() => handleReservationDelete(reservation.id, reservation.titolare_email) }>
                                  <DynamicIcon iconName='FaTrash' />
                                  <Tooltip anchorSelect=".reservation_del" place="top" variant="info">Annulla prenotazione</Tooltip>
                                </Button>
                                {/* <Button onClick={() => { }} className="delete">
                                  <DynamicIcon iconName='FaTrash' />
                                  <Tooltip anchorSelect=".delete" place="top" variant="info">Cancella reservation</Tooltip>
                                </Button> */}
                              </td>
                            </tr>
                    }) : '' }
                </tbody>
              </Table>
              { numPages > 1 ?
                <div className='pageListWrapper'>
                  <ul className='pagesList'>
                    { [...Array(numPages)].map(function(obj, i) {
                      let ii = i+1;
                      if (numPages > 5)
                        switch (parseInt(ii)) {
                          case 1:
                          case (page-1):
                          case page:
                          case page+1:
                          case numPages:
                            return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                          default:
                            return '';
                        }
                      else
                        return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                    }) }
                  </ul>
                </div>
              : '' }
            </>
          : <p>Nessuna prenotazione presente</p> }
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminReservations;