import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminVouchersView() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
  // DATA
  const [voucher, setVoucher] = useState(null);
  const [escTypes, setEscTypes] = useState([]);

  useEffect(() => {
    if (state && state.voucher_id) {
      axios.post(window.SERVER_URL+'slot_types/list', {}, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(res => {
        console.log(res.data);
        setEscTypes(res.data);
  
        axios.post(window.SERVER_URL+'vouchers/get', { id: state.voucher_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
          setVoucher(res.data);
          setValue('id', res.data.id);
          setValue('unita', 'euro');
          setValue('scadenza', res.data.scadenza ? res.data.scadenza.substr(0, 10) : null);
        });
      });
    } else {
      navigate('/admin/vouchers');
    }
  }, []);

  const handleChangeType = (type_id) => {
    console.log(type_id);
    escTypes.forEach((type) => {
      if (type.id == type_id) {
        console.log(type.id+' => '+type.descrizione);
        setValue('valore', (parseInt(type.prezzo_guidatore) > 0 ? parseInt(type.prezzo_guidatore) : parseInt(type.prezzo_passeggero)));
      }
    });
  }

  const onSubmit = (form_data) => {
    axios.post(window.SERVER_URL+'vouchers/validate', form_data, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Operazione completata',
          html: "Il buono è stato validato. È stata inviata un'email al cliente con tutti i dettagli.",
        }).then((result) => {
          navigate('/admin/vouchers');
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: 'La validazione del buono non è andata a buon fine'
        });
      }
    });
  }
  
  return (
    <Container className="content admin">
      <Row className="page_title">
        <Col>
          <h1>Dettaglio buono partner</h1>
        </Col>
      </Row>
      <Row>
        <Col className='tab_wrapper'>
          { voucher ? 
            <>
              <Table responsive className='detail'>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{ voucher.id }</td>
                  </tr>
                  <tr>
                    <td>Data richiesta</td>
                    <td>{ voucher.created_at ? voucher.created_at.substr(0, 10).split("-").reverse().join("/")+' '+voucher.created_at.substr(11, 5) : '-' }</td>
                  </tr>
                  <tr>
                    <td>Nome e cognome</td>
                    <td>{ voucher.nome }</td>
                  </tr>
                  <tr>
                    <td>Telefono</td>
                    <td>{ voucher.telefono }</td>
                  </tr>
                  <tr>
                    <td>Indirizzo e-mail</td>
                    <td>{ voucher.email }</td>
                  </tr>
                  <tr>
                    <td>Piattaforma partner</td>
                    <td>{ voucher.voucher_partner ? voucher.voucher_partner.nome : '-' }</td>
                  </tr>
                  <tr>
                    <td>Codice voucher</td>
                    <td>{ voucher.codice.toUpperCase() }</td>
                  </tr>
                  <tr>
                    <td>Data di scadenza</td>
                    <td>{ voucher.scadenza ? voucher.scadenza.substr(0, 10).split("-").reverse().join("/") : '-' }</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <a href={ voucher.immagine_url } target="_blank"><img style={{'maxHeight':'250px'}} src={ voucher.immagine_url } /></a>
                    </td>
                  </tr>
                  { !voucher.validato ?
                    <tr>
                      <td colspan="2">
                        <form className="custom_form" onSubmit={handleSubmit(onSubmit)} style={{'textAlign':'left','width':'400px','margin':'20px 0'}}>
                          <input type="hidden" {...register("id", { required: true })} />
                          <input type="hidden" {...register("unita", { required: true })} />
                          <label>Tipologia di escursione</label>
                          <select {...register("slot_type_id", { required: true })} onChange={(e) => handleChangeType(e.target.value)}>
                            <option value="">- Scegli tipologia -</option>
                            { escTypes && escTypes.length ? escTypes.map((type) => 
                              type.codice != '3H/2P/CENA' ?
                                <option key={type.id} value={type.id}>{ type.descrizione }</option>
                              : ''
                            ) : '' }
                          </select>
                          <label>Valore del buono (€)</label>
                          <input type="number" step="1" placeholder="Valore" {...register("valore", { required: true })} />
                          {/* <label>Tipologia di sconto</label>
                          <select {...register("unita", { required: true })}>
                            <option value="euro">euro</option>
                            <option value="percent">percent</option>
                          </select> */}
                          <label>Data scadenza buono</label>
                          <input type="date" min={new Date().toISOString().split('T')[0]} placeholder="Scadenza buono regalo" {...register("scadenza", { required: true })} />
                          <Button type="submit" variant="primary" className="waste_btn">Valida buono</Button>
                        </form>
                      </td>
                    </tr>
                  : '' }
                </tbody>
              </Table>
              { voucher.validato && voucher.coupon ? 
                <Row className='admin_detail'>
                  <Col>
                    Validato il<br />
                    <span className='tag approved' style={{'display':'inline-block','margin':'0 5px 0 0'}}></span> <b>{ voucher.coupon.created_at.substr(0, 10).split("-").reverse().join("/")+' '+voucher.coupon.created_at.substr(11, 5) }</b>
                  </Col>
                  <Col>
                    Codice generato<br />
                    <b>{ voucher.coupon.code }</b>
                  </Col>
                  <Col>
                    Tipo escursione<br />
                    <b>{ voucher.coupon.tipo ? voucher.coupon.tipo.type : '-' }</b>
                  </Col>
                  <Col>
                    Valore nuovo coupon<br />
                    <b>{ voucher.coupon.amount+(voucher.coupon.unit == 'euro' ? '€' : '%') }</b>
                  </Col>
                  <Col>
                    Scadenza il<br />
                    <b>{ voucher.coupon.expire_at.substr(0, 10).split("-").reverse().join("/")+' '+voucher.coupon.expire_at.substr(11, 5) }</b>
                  </Col>
                </Row>
              : '' }
            </>
          : '' }
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminVouchersView;