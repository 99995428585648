import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminReservationsEdit() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();
  // DATA
  const [reservation, setReservation] = useState(null);

  useEffect(() => {
    if (state && state.reservation_id) {
      axios.post(window.SERVER_URL+'reservations/get', { id: state.reservation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setReservation(res.data);

        // Setto i campi della prenotazione
        let xfields = {};
        for (const [key, val] of Object.entries(res.data))
          if (typeof val !== 'object')
            xfields['reservation['+key+']'] = val;
        // Setto i campi dei partecipanti
        if (res.data.clients) {
          res.data.clients.forEach((client, i) => {
            for (const [key, val] of Object.entries(client))
              if (typeof val !== 'object')
                xfields['clients['+i+']['+key+']'] = val;
          });
        }
        reset(xfields);
      });
    } else {
      navigate('/admin/reservations');
    }
  }, []);

  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'reservations/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(res => {
      console.log(res.data);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Prenotazione aggiornata',
          html: "La prenotazione è stata aggiornata"
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Si è verificato un errore',
          html: "La tua prenotazione non è stata aggiornata<br />Riprova per favore",
          confirmButtonColor: "#314491",
          confirmButtonText: "Ok"
        });
      }
    });
  }
  
  return (
    <Container className="content admin">
      { reservation ? 
        <>
          <Row className="page_title">
            <Col>
              <h1>Prenotazione <span>{ reservation.codice }</span></h1>
            </Col>
          </Row>
          <Row>
            <Col className='tab_wrapper'>
              { reservation ? 
                <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
                  <input type="hidden" {...register("reservation[id]", { required: true })} />
                  <Row className='panel'>
                    <Col className="custom_form_wrapper">
                      {/* <h4>Data prenotazione</h4> */}
                      <Row>
                        <Col lg={1}>
                          ID<br />
                          <b>{ reservation.id }</b>
                        </Col>
                        <Col>
                          Effettuata in data<br />
                          { reservation.previous ?
                            <b>{ reservation.previous.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.previous.created_at.substr(11,5) : '-' }</b>
                          :
                            <b>{ reservation.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.created_at.substr(11,5) : '-' }</b>
                          }
                        </Col>
                        <Col>
                          Escursione prenotata: <b>{ reservation.slot.slot_type.descrizione }</b><br />
                          Data e ora escursione: <b>{ reservation.slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+reservation.slot.inizio.substr(11,5) }</b>
                        </Col>
                        { !reservation.deleted_at ?
                          <Col>
                            <Button onClick={() => navigate('/admin/slots/view', { state: { slot_id: reservation.slot.id } }) }><DynamicIcon iconName='FaCalendarDay' /> Vai all'escursione</Button>
                          </Col>
                        : '' }
                      </Row>
                      <Row className='admin_detail'>
                        <Col>
                          Stato<br />
                          <div className={'tag '+reservation.stato} style={{'display':'inline-block','margin':'0 5px 0 0'}}></div> <b>{ reservation.stato == 'approved' ? 'PAGATA' : (reservation.stato == 'suspended' ? 'NON PAGATA' : 'IN FASE DI CREAZIONE') }</b>
                        </Col>
                        <Col>
                          Importo escursione<br />
                          <b>{ reservation.pagamento_importo ? parseInt(reservation.pagamento_importo)+' €' : '-' }</b>
                        </Col>
                        <Col>
                          Data pagamento<br />
                          <b>{ reservation.pagamento_data ? reservation.pagamento_data.substr(0, 10).split("-").reverse().join("/")+' '+reservation.pagamento_data.substr(11,5) : '-' }</b>
                        </Col>
                        <Col>
                          Tipo pagamento<br />
                          <b>{ reservation.pagamento_tipo ? reservation.pagamento_tipo : '-' }</b>
                        </Col>
                        { reservation.stato == 'approved' && reservation.pagamento_tipo == 'online' ?
                          <Col>
                            ID transazione PayPlug<br />
                            <b>{ reservation.pagamento_id_transazione ? reservation.pagamento_id_transazione : '-' }</b>
                          </Col>
                        : '' }
                        <Col>
                          Buono regalo utilizzato<br />
                          <b>{ reservation.voucher ? reservation.voucher+(reservation.coupon ? ' [-'+reservation.coupon.amount+(reservation.coupon.unit == 'euro' ? '€' : '%')+']' : '') : 'No' }</b>
                        </Col>
                      </Row>
                      { reservation.previous ?
                        <>
                          <Row className='admin_detail alert'>
                            <Col style={{'padding':'10px 0'}}>
                              Spostata in data<br />
                              <b>{ reservation.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.created_at.substr(11,5) : '-' }</b>
                            </Col>
                            <Col style={{'padding':'10px 0'}}>
                              Data e ora precedenti<br />
                              <b>{ reservation.previous.slot ? reservation.previous.slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+reservation.previous.slot.inizio.substr(11,5) : '-' }</b>
                            </Col>
                          </Row>
                          <br />
                        </>
                      : '' }
                      <h4>Dati titolare prenotazione</h4>
                      <Row>
                        <Col>
                          <label>Nome</label>
                          <input type="text" placeholder="Nome" {...register("reservation[titolare_nome]", { required: true })} />
                        </Col>
                        <Col>
                          <label>Cognome</label>
                          <input type="text" placeholder="Cognome" {...register("reservation[titolare_cognome]", { required: true })} />
                        </Col>
                        <Col>
                          <label>Cellulare</label>
                          <input type="text" placeholder="Cellulare" {...register("reservation[titolare_telefono]", { required: true })} />
                        </Col>
                      </Row>
                      <Row style={{'marginTop':'10px'}}>
                        <Col>
                          <label>E-mail</label>
                          <input type="email" placeholder="E-mail" {...register("reservation[titolare_email]", { required: true })} />
                        </Col>
                        <Col lg={2}>
                          <label>Età</label>
                          <input type="number" min="18" max="100" step="1" placeholder="Età" {...register("reservation[titolare_eta]", { required: true })} />
                        </Col>
                        <Col lg={2}>
                          <label>Genere</label>
                          <select {...register("reservation[titolare_genere]", { required: true })}>
                            <option value="">-</option>
                            <option value="M">Uomo</option>
                            <option value="F">Donna</option>
                            <option value="X">Preferisco non specificare</option>
                          </select>
                        </Col>
                        <Col>
                          <label>Come ci hai trovato?</label>
                          <select {...register("reservation[marketing_info]")}>
                            <option value="">-</option>
                            <option value="Volantini">Volantini</option>
                            <option value="Già cliente">Già cliente</option>
                            <option value="Internet">Internet</option>
                            <option value="Passaparola">Passaparola</option>
                            <option value="Fiera o evento">Fiera / evento</option>
                          </select>
                        </Col>
                      </Row>
                      <hr />
                      <h4>Dati partecipanti</h4>
                      <div id='persons_wrapper'>
                        {/* GUIDATORI */}
                        { reservation && reservation.clients && reservation.clients.length ? reservation.clients.map((client, i) => (
                          client.client_type_id == 1 ?
                            <Row className='person_row'>
                              <input type="hidden" {...register(`clients[${i}][id]`, { required: true })} />
                              <input type="hidden" {...register(`clients[${i}][reservation_id]`, { required: true })} />
                              <input type="hidden" {...register(`clients[${i}][client_type_id]`, { required: true })} />
                              <p><DynamicIcon iconName='GiSteeringWheel' size="24" /> GUIDATORE</p>
                              <Col>
                                <label style={{'marginRight':'20px'}}>Nome</label>
                                <input type="text" placeholder="Nome" {...register(`clients[${i}][nome]`, { required: true })} />
                              </Col>
                              <Col>
                                <label>Cognome</label>
                                <input type="text" placeholder="Cognome" {...register(`clients[${i}][cognome]`, { required: true })} />
                              </Col>
                              <Col>
                                <label>Età</label>
                                <input type="number" min="1" max="100" step="1" placeholder="Età" {...register(`clients[${i}][eta]`, { required: true })} />
                              </Col>
                            </Row>
                          : '' )
                        ) : '' }
                        {/* PASSEGGERI */}
                        { reservation && reservation.clients && reservation.clients.length ? reservation.clients.map((client, i) => (
                          client.client_type_id == 2 ?
                            <Row className='person_row'>
                              <input type="hidden" value="2" {...register(`clients[${i}][client_type_id]`, { required: true })} />
                              <p><DynamicIcon iconName='MdEventSeat' size="24" /> PASSEGGERO</p>
                              <Col>
                                <label style={{'marginRight':'20px'}}>Nome</label>
                                <input type="text" placeholder="Nome" {...register(`clients[${i}][nome]`, { required: true })} />
                              </Col>
                              <Col>
                                <label>Cognome</label>
                                <input type="text" placeholder="Cognome" {...register(`clients[${i}][cognome]`, { required: true })} />
                              </Col>
                              <Col>
                                <label>Età</label>
                                <input type="number" min="1" max="100" step="1" placeholder="Età" {...register(`clients[${i}][eta]`, { required: true })} />
                              </Col>
                            </Row>
                          : '' )
                        ) : '' }
                      </div>
                      { !reservation.deleted_at ?
                        <Button type="submit" variant="success" className="waste_btn">Aggiorna prenotazione</Button>
                      : '' }
                    </Col>
                  </Row>
                </form>
              : '' }
            </Col>
          </Row>
        </>
      : '' }
    </Container>
  );
  
}

export default AdminReservationsEdit;