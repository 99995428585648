import React, { useContext, useState } from 'react';
// AUTH
import { AuthContext } from './contexts/AuthContext';
// REACT ROUTER
import { Outlet } from "react-router-dom";
// PARTIALS
import Header from './partials/header';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';

function Main() {
  // AUTH, STATE, NAVIGATION
  const { authobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  
  return (
    <>
      { authobj && authobj.token && window.location.pathname != '/admin' && window.location.pathname != '/admin/' && window.location.pathname.startsWith('/admin') ? <Header /> : ''}
      <Outlet />
    </>
  );
}

export default Main;