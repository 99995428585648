import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// TIMEPICKER
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminSlotsAdd() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();
  // DATA
  const [slotTypes, setSlotTypes] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    axios.post(window.SERVER_URL+'slot_types/list', { }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setSlotTypes(res.data);
    });
  }, []);

  function formatTime(date) {
    const hours = String(date.getHours()).padStart(2, '0'); // Get hours and pad with zero if needed
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Get minutes and pad with zero
    const seconds = String(date.getSeconds()).padStart(2, '0'); // Get seconds and pad with zero 
    return `${hours}:${minutes}:${seconds}`; // Return formatted string
  }

  function roundToNearest15Minutes(date) {
    const roundedDate = new Date(date);
    const minutes = roundedDate.getMinutes();
    const remainder = minutes % 15;
    if (remainder >= 8) {
      roundedDate.setMinutes(minutes + (15 - remainder));
    } else {
      roundedDate.setMinutes(minutes - remainder);
    }
    roundedDate.setSeconds(0);
    roundedDate.setMilliseconds(0);
    return roundedDate;
  }

  const handleTimeChange = (type, val) => {
    console.log("VAL :::::: "+val);
    const roundedTime = roundToNearest15Minutes(val);
    if (type == 'start')
      setStartTime(roundedTime);
    else
      setEndTime(roundedTime);
  };

  const onSubmit = (form_data) => {
    form_data.inizio = form_data.inizio_data+' '+formatTime(startTime);
    form_data.fine = form_data.inizio_data+' '+formatTime(endTime); // qui ho messo inizio_data invece che fine_data perché le escursioni non stanno mai a cavallo tra 2 giornate e ho tolto il campo fine_data dalla form
    delete form_data.inizio_data;
    delete form_data.fine_data;

    console.log(form_data);

    axios.post(window.SERVER_URL+'slots/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }
    }).then(res => {
      console.log(res.data);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Escursione salvata',
          html: "L'escursione' è stata salvata"
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Si è verificato un errore',
          html: "L'escursione non è stata salvata<br />Riprova per favore",
          confirmButtonColor: "#314491",
          confirmButtonText: "Ok"
        });
      }
    });
  }
  
  return (
    <Container className="content admin">
      <Row className="page_title">
        <Col>
          <h1>Nuova escursione</h1>
        </Col>
      </Row>
      <Row>
        <Col className='tab_wrapper'>
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row className='panel'>
              <Col className="custom_form_wrapper">
                <Row>
                  <Col>
                    <label>Data escursione</label>
                    <input type="date" {...register("inizio_data", { required: true })} />
                  </Col>
                  <Col>
                    <label>Orario inizio escursione</label><br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker views={['hours', 'minutes']} ampm={false} ampmInClock={false} format="HH:mm" minutesStep={15} value={startTime} onChange={(val) => handleTimeChange('start', val)} />
                    </LocalizationProvider>
                  </Col>
                  <Col>
                    <label>Orario fine escursione</label><br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker views={['hours', 'minutes']} ampm={false} ampmInClock={false} format="HH:mm" minutesStep={15} value={endTime} onChange={(val) => handleTimeChange('end', val)} />
                    </LocalizationProvider>
                  </Col>
                </Row>
                {/* <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label>Data fine escursione</label>
                    <input type="date" {...register("fine_data", { required: true })} />
                  </Col>
                </Row> */}
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label>Tipologia escursione</label>
                    <select {...register("slot_type_id", { required: true })}>
                      <option value="">- Seleziona tipologia escursione -</option>
                      { slotTypes ? slotTypes.map((type) => 
                        <option key={type.id} value={type.id}>{ type.descrizione }</option>
                      ) : '' }
                    </select>
                  </Col>
                  <Col>
                    <label>Numero MAX voucher</label>
                    <input type="number" step="1" {...register("max_voucher")} />
                  </Col>
                </Row>
                {/* <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label>Numero MAX guidatori</label>
                    <input type="number" step="1" {...register("num_guidatori", { required: true })} />
                  </Col>
                  <Col>
                    <label>Numero MAX passeggeri</label>
                    <input type="number" step="1" {...register("num_passeggeri", { required: true })} />
                  </Col>
                </Row> */}
                <Button type="submit" variant="success" className="waste_btn">Salva escursione</Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminSlotsAdd;