import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminReservationsAdd() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, setValue, getValues, watch, reset, formState: { errors } } = useForm();
  const { fields: d_fields, append: d_append, prepend: d_prepend, remove: d_remove, swap: d_swap, move: d_move, insert: d_insert } = useFieldArray({
    control,
    name: "d_clients"
  });
  const { fields: p_fields, append: p_append, prepend: p_prepend, remove: p_remove, swap: p_swap, move: p_move, insert: p_insert } = useFieldArray({
    control,
    name: "p_clients"
  });
  // DATA
  const [slot, setSlot] = useState(null);

  useEffect(() => {
    if (state && state.slot_id) {
      axios.post(window.SERVER_URL+'slots/get', { id: state.slot_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setSlot(res.data);
        setValue('reservation[slot_id]', state.slot_id);
      });
    } else {
      navigate('/admin/reservations');
    }
  }, []);

  const add_d_client = () => {
    d_append({
      client_type_id: null,
      nome: null,
      cognome: null,
      eta: null
    });
  }

  const add_p_client = () => {
    p_append({
      client_type_id: null,
      nome: null,
      cognome: null,
      eta: null
    });
  }

  const fillClient = (type, i) => {
    console.log(getValues());
    console.log(i);
    setValue(`${type}_clients[${i}][nome]`, getValues('reservation[titolare_nome]'));
    setValue(`${type}_clients[${i}][cognome]`, getValues('reservation[titolare_cognome]'));
    setValue(`${type}_clients[${i}][eta]`, getValues('reservation[titolare_eta]'));
  }

  const onSubmit = (form_data) => {
    let tmp_clients = [];
    let clients_ok = true;
    // Inserisco nell'elenco dei clienti i GUIDATORI
    if (form_data.d_clients && form_data.d_clients.length) {
      form_data.d_clients.forEach((c) => {
        let tmp_c = c;
        if (!tmp_c.nome || !tmp_c.cognome || !tmp_c.eta)
          clients_ok = false;
        tmp_c.client_type_id = 1; // Tipologia: Guidatore
        tmp_clients.push(tmp_c);
      });
    }
    // Inserisco nell'elenco dei clienti i PASSEGGERI
    if (form_data.p_clients && form_data.p_clients.length) {
      form_data.p_clients.forEach((c) => {
        let tmp_c = c;
        if (!tmp_c.nome || !tmp_c.cognome || !tmp_c.eta)
          clients_ok = false;
        tmp_c.client_type_id = 2; // Tipologia: Passeggero
        tmp_clients.push(tmp_c);
      });
    }
    if (tmp_clients.length)
      form_data['clients'] = tmp_clients;
    delete form_data['d_clients'];
    delete form_data['p_clients'];

    if (form_data['reservation']['pagamento_effettuato'] == '1') {
      form_data["reservation"]["pagamento_effettuato"] = "1";
      form_data["reservation"]["pagamento_data"] = new Date().toLocaleString('sv-SE').replace('T', ' ');
    }

    console.log(form_data);
    if (tmp_clients.length) {
      if (clients_ok) {
        axios.post(window.SERVER_URL+'reservations/save', form_data, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }
        }).then(res => {
          console.log(res.data);
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Prenotazione salvata',
              html: "La prenotazione è stata salvata"
            }).then(res => {
              navigate('/admin/slots/view', { state: { slot_id: state.slot_id }});
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Si è verificato un errore',
              html: "La tua prenotazione non è stata salvata<br />Riprova per favore",
              confirmButtonColor: "#314491",
              confirmButtonText: "Ok"
            });
          }
        });
      } else {  // validazione clienti non è andata a buon fine
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non hai caricato tutti i valori obbligatori per i partecipanti"
        });
      }
    } else {  // validazione numero clienti non è andata a buon fine
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi inserire almeno un partecipante nell'escursione"
      });
    }
  }
  
  return (
    <Container className="content admin">
      { slot ? 
        <>
          <Row className="page_title">
            <Col>
              <h1>Nuova prenotazione</h1>
            </Col>
          </Row>
          <Row>
            <Col className='tab_wrapper'>
              <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" value="admin" {...register("side")} />
                <input type="hidden" {...register("reservation[slot_id]")} />
                <Row className='panel'>
                  <Col className="custom_form_wrapper">
                    <Row className='admin_detail'>
                      <Col>
                        Tipo escursione<br />
                        <b>{ slot.slot_type.descrizione }</b>
                      </Col>
                      <Col>
                        Data e ora escursione<br />
                        <b>{ slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+slot.inizio.substr(11,5) }</b>
                      </Col>
                      <Col>
                        Guidatori disponibili<br />
                        <b>{ slot.utenti.guidatori.disponibili }</b>
                      </Col>
                      <Col>
                        Passeggeri disponibili<br />
                        <b>{ slot.utenti.passeggeri.disponibili }</b>
                      </Col>
                      <Col>
                        <Button onClick={() => navigate('/admin/slots/view', { state: { slot_id: state.slot_id } }) }>Torna all'escursione</Button>
                      </Col>
                    </Row>
                    <h4>Dati titolare prenotazione</h4>
                    <Row>
                      <Col>
                        <label>Nome</label>
                        <input type="text" placeholder="Nome" {...register("reservation[titolare_nome]", { required: true })} />
                      </Col>
                      <Col>
                        <label>Cognome</label>
                        <input type="text" placeholder="Cognome" {...register("reservation[titolare_cognome]", { required: true })} />
                      </Col>
                      <Col>
                        <label>Cellulare</label>
                        <input type="text" placeholder="Cellulare" {...register("reservation[titolare_telefono]", { required: true })} />
                      </Col>
                    </Row>
                    <Row style={{'marginTop':'10px'}}>
                      <Col>
                        <label>E-mail</label>
                        <input type="email" placeholder="E-mail" {...register("reservation[titolare_email]", { required: true })} />
                      </Col>
                      <Col lg={2}>
                        <label>Età</label>
                        <input type="number" min="18" max="100" step="1" placeholder="Età" {...register("reservation[titolare_eta]", { required: true })} />
                      </Col>
                      <Col lg={2}>
                        <label>Genere</label>
                        <select {...register("reservation[titolare_genere]", { required: true })}>
                          <option value="">-</option>
                          <option value="M">Uomo</option>
                          <option value="F">Donna</option>
                          <option value="X">Preferisco non specificare</option>
                        </select>
                      </Col>
                      <Col>
                        <label>Come ci hai trovato?</label>
                        <select {...register("reservation[marketing_info]")}>
                          <option value="">-</option>
                          <option value="Volantini">Volantini</option>
                          <option value="Già cliente">Già cliente</option>
                          <option value="Internet">Internet</option>
                          <option value="Passaparola">Passaparola</option>
                          <option value="Fiera o evento">Fiera / evento</option>
                        </select>
                      </Col>
                    </Row>
                    <hr />
                    <h4>Dati partecipanti</h4>
                    <Row>
                      <Col className='action_bar bottom' style={{'textAlign':'center'}}>
                        { d_fields.length < slot.utenti.guidatori.disponibili ?
                          <Button onClick={() => { add_d_client() }}><DynamicIcon iconName='FaPlus' /> Aggiungi guidatore</Button>
                        : '' }
                        { p_fields.length < slot.utenti.passeggeri.disponibili ?
                          <Button onClick={() => { add_p_client() }}><DynamicIcon iconName='FaPlus' /> Aggiungi passeggero</Button>
                        : '' }
                      </Col>
                    </Row>
                    <div id='persons_wrapper' style={{'marginTop':'40px'}}>
                      {/* GUIDATORI */}
                      { d_fields.map((item, i) => (
                        <Row className='person_row'>
                          <p><DynamicIcon iconName='GiSteeringWheel' size="24" /> GUIDATORE <span onClick={() => d_remove(i) }><DynamicIcon iconName='ImCross' size="20" /></span></p>
                          <Col>
                            <label style={{'marginRight':'20px'}}>Nome</label>
                            <input type="text" placeholder="Nome" {...register(`d_clients[${i}][nome]`)} />
                          </Col>
                          <Col>
                            <label>Cognome</label>
                            <input type="text" placeholder="Cognome" {...register(`d_clients[${i}][cognome]`)} />
                          </Col>
                          <Col>
                            <label>Età</label>
                            <input type="number" min="1" max="100" step="1" placeholder="Età" {...register(`d_clients[${i}][eta]`)} />
                          </Col>
                          <Col>
                            <a className='hint_link' onClick={() => fillClient('d', i)}><DynamicIcon iconName='FaHandPointUp' /> Usa dati titolare</a>
                          </Col>
                        </Row>
                      ))}
                      {/* PASSEGGERI */}
                      { p_fields.map((item, i) => (
                        <Row className='person_row'>
                          <p><DynamicIcon iconName='MdEventSeat' size="24" /> PASSEGGERO <span onClick={() => p_remove(i) }><DynamicIcon iconName='ImCross' size="20" /></span></p>
                          <Col>
                            <label style={{'marginRight':'20px'}}>Nome</label>
                            <input type="text" placeholder="Nome" {...register(`p_clients[${i}][nome]`)} />
                          </Col>
                          <Col>
                            <label>Cognome</label>
                            <input type="text" placeholder="Cognome" {...register(`p_clients[${i}][cognome]`)} />
                          </Col>
                          <Col>
                            <label>Età</label>
                            <input type="number" min="1" max="100" step="1" placeholder="Età" {...register(`p_clients[${i}][eta]`)} />
                          </Col>
                          <Col>
                            <a className='hint_link' onClick={() => fillClient('p', i)}><DynamicIcon iconName='FaHandPointUp' /> Usa dati titolare</a>
                          </Col>
                        </Row>
                      ))}
                    </div>
                    <hr />
                    <h4>Dati pagamento</h4>
                    <Row>
                      <Col>
                        <label>Stato pagamento</label>
                        <select {...register("reservation[pagamento_effettuato]", { required: true })}>
                          <option value="">- Scegli -</option>
                          <option value="1">Pagata</option>
                          <option value="0">Da pagare</option>
                        </select>
                      </Col>
                      <Col>
                        <label>Importo pagamento (€)</label>
                        <input type="number" step="5" {...register("reservation[pagamento_importo", { required: true })} />
                      </Col>
                      <Col>
                        <label>Metodo di pagamento</label>
                        <select {...register("reservation[pagamento_tipo]", { required: true })}>
                          <option value="">- Seleziona metodo pagamento -</option>
                          <option value="online">Online</option>
                          <option value="contanti">Contanti</option>
                          <option value="pos">POS</option>
                          <option value="bonifico">Bonifico</option>
                        </select>
                      </Col>
                    </Row>
                    <Button type="submit" variant="success" className="waste_btn">Salva prenotazione</Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </>
      : '' }
    </Container>
  );
  
}

export default AdminReservationsAdd;