import React, { useState, useEffect, useContext, useRef } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, useParams, redirect } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import FrontHeader from '../partials/header_front';
// CALENDAR
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';

function Manage() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  const { hashAccess } = useParams();
  // FORM
  const { register, control, handleSubmit, trigger, getValues, setValue, reset, formState: { errors } } = useForm();
  // CUSTOM HOOKS
  const { handleReservationDelete, handlePayment } = useHelpers();
  // CALENDAR
  const calendarRef = useRef(null);
  // DATA
  const [reservation, setReservation] = useState(null);
  const [move, setMove] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [slots, setSlots] = useState([]);
  // DATA - RESERVATION
  const [drivers, setDrivers] = useState(0);
  const [passengers, setPassengers] = useState(0);
  const [escTypeSelected, setEscTypeSelected] = useState(null);
  const [voucher, setVoucher] = useState(null);

  useEffect(() => {
    if (hashAccess) {
      const [titolare_email, codice] = atob(hashAccess).split('|');
      handleSubmit(onSubmit({titolare_email, codice}));
    }
  }, [globalRefresh]);

  const onSubmit = (form_data) => {
    if (form_data.titolare_email && form_data.codice) {
      axios.post(window.SERVER_URL+'reservations/get', { titolare_email: form_data.titolare_email, codice: form_data.codice }, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(res => {
        console.log(res.data);
        if (res.data) {
          setReservation(res.data);
          // Setto i campi fondamentali della reservation sullo state in modo che posso usarli per il retrieve degli slot del calendario
          setDrivers(res.data.utenti.guidatori);
          setPassengers(res.data.utenti.passeggeri);
          setEscTypeSelected(res.data.slot.slot_type_id);
          setVoucher(res.data.voucher);

          retrieveSlots(res.data.utenti.guidatori, res.data.utenti.passeggeri, res.data.slot.slot_type_id, res.data.voucher);

          Swal.fire({
            icon: 'success',
            title: 'Prenotazione trovata',
            html: "Ora puoi gestire la tua prenotazione"
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Prenotazione non trovata',
            html: "Impossibile trovare una prenotazione con i dati inseriti, controlla e riprova per favore"
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Dati mancanti',
        html: "Inserisci tutti i dati richiesti"
      });
    }
  }

  const retrieveSlots = (drivers, passengers, escTypeSelected, voucher) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const startDate = calendarApi.view.currentStart;
      const weekStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;

      axios.post(window.SERVER_URL+'slots/list', {
        num_guidatori: drivers, 
        num_passeggeri: passengers, 
        slot_type_id: escTypeSelected, 
        inizio_settimana: weekStartDate, 
        voucher: voucher 
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(res => {
        console.log(res.data);
        
        if (res.data.length) {
          const fetchedEvents = res.data.map((event) => ({
            ...event,
            classNames: event.disponibile ? [] : ['disabled-event'],
          }));
          setSlots(fetchedEvents);
        } else {
          setSlots([]);
        }
      });
    }
  }

  const handleDatesSet = () => {
    retrieveSlots(drivers, passengers, escTypeSelected, voucher);
  }

  const handleEventClick = (info) => {
    if (info.event.classNames.includes('disabled-event')) {
      info.jsEvent.preventDefault();
      alert('Questo evento non è selezionabile');
    } else {
      const startDate = info.event.start;
      const formattedDate = `${String(startDate.getDate()).padStart(2, '0')}/${String(startDate.getMonth() + 1).padStart(2, '0')}/${startDate.getFullYear()}`;    
      const formattedTime = `${String(startDate.getHours()).padStart(2, '0')}:${String(startDate.getMinutes()).padStart(2, '0')}`;
      
      Swal.fire({
        html: `<img class="logo" src="https://escursionimotoslitte.it/img/escursioni-motoslitte-logo.svg"><br>
                <p><b>Stai per spostare l'escursione</b><br /><span style="color: #314491">${reservation.codice} - ${info.event.title}</span></p>
                <p><b>Nuova data:</b> <span style="color: #314491">${formattedDate}</span> - <b>Nuovo orario:</b> <span style="color: #314491">${formattedTime}</span></p>
                <p><b>Vuoi procedere con lo spostamento?</p>`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Conferma',
        denyButtonText: 'Annulla',
        confirmButtonColor: "#314491",
        cancelButtonColor: "#cb4f2c",
        width: '600px',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(window.SERVER_URL+'reservations/move', { id: reservation.id, slot_id: info.event.id }, {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }).then(res => {
            console.log(res.data);
            if (res.data.success) {
              Swal.fire({
                icon: 'success',
                title: 'Prenotazione spostata',
                html: "Ti è stata inviata un'e-mail di conferma"
              }).then((result) => {
                axios.post(window.SERVER_URL+'reservations/get', { id: res.data.new_id }, {
                  headers: {
                    'Access-Control-Allow-Origin': '*'
                  }
                }).then(res => {
                  console.log(res.data);
                  if (res.data) {
                    setReservation(res.data);
                    setMove(false);
                  } else {
                    navigate('/manage');
                  }
                });
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Spostamento fallito',
                text: "Si è verificato un errore, per favore riprova",
                confirmButtonColor: "#314491",
                confirmButtonText: "Torna all'inizio"
              }).then((result) => {
                navigate('/manage');
              });
            }
          });
        }
      });
    }
  }

  const handleMoveReservation = () => {
    if (reservation) {
      if (reservation.pagamento_effettuato == 1 && !reservation.previous && new Date() < new Date(reservation.slot.inizio) && (new Date(reservation.slot.inizio) - new Date()) > 72*60*60*1000) {  // La data/ora attuale è precedente alla data/ora escursione di almeno 72h
        setMove(true);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Spostamento non consentito',
          html: `<h5>Cosa potrebbe essere successo:</h5>
                - La tua prenotazione <b>non è stata ancora pagata</b><br>
                - La tua prenotazione risulta <b>già spostata</b> una volta<br>
                - Mancano <b>meno di 72h</b> all'inizio dell'escursione`,
        });
      }
    }
  }
  
  return (
    <Container className="front_content">
      <FrontHeader />
      <Row id='subheader'>
        <Col>
          <h2>Gestisci la tua prenotazione</h2>
        </Col>
      </Row>
      { !reservation && !move ?
        <>
          <Row className='panel'>
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Col style={{'maxWidth':'600px','margin':'0 auto','textAlign':'center'}}>
                <label>Indirizzo e-mail prenotazione</label>
                <input type="email" placeholder="E-mail prenotazione" {...register("titolare_email", { required: true })} style={{'textAlign':'center'}} />
                <br /><br />
                <label>Codice prenotazione</label>
                <input type="text" placeholder="Codice prenotazione" {...register("codice", { required: true })} style={{'textAlign':'center'}} />
                <Button type="submit" variant="success" className="waste_btn">Ricerca prenotazione</Button>
              </Col>
            </form>
          </Row>
        </>
      : reservation && !move ?
        <>
          <Row className='panel'>
            <h4>Dati titolare</h4>
            <Row>
              <Col>
                Nome<br />
                <b>{ reservation.titolare_nome }</b>
                <br /><br />
                Cognome<br />
                <b>{ reservation.titolare_cognome }</b>
              </Col>
              <Col>
                Recapito telefonico<br />
                <b>{ reservation.titolare_telefono }</b>
                <br /><br />
                Indirizzo e-mail<br />
                <b>{ reservation.titolare_email }</b>
              </Col>
              <Col>
                Età<br />
                <b>{ reservation.titolare_eta }</b>
                <br /><br />
                Genere<br />
                <b>{ reservation.titolare_genere == 'M' ? 'Uomo' : (reservation.titolare_genere == 'F' ? 'Donna' : 'Preferisco non specificare') }</b>
              </Col>
              <Col>
                Come ci hai trovato<br />
                <b>{ reservation.marketing_info }</b>
              </Col>
            </Row>
            <hr />
            <h4>Dati prenotazione</h4>
            <Row>
              <Col>
                Codice prenotazione<br />
                <b>{ reservation.codice }</b>
              </Col>
              <Col>
                Effettuata in data<br />
                { reservation.previous ?
                  <b>{ reservation.previous.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.previous.created_at.substr(11,5) : '-' }</b>
                :
                  <b>{ reservation.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.created_at.substr(11,5) : '-' }</b>
                }
              </Col>
              <Col>
                Escursione prenotata<br />
                <b>{ reservation.slot.slot_type.descrizione }</b>
              </Col>
              <Col>
                Data e ora escursione<br />
                <b>{ reservation.slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+reservation.slot.inizio.substr(11, 5) }</b>
              </Col>
            </Row>
            { reservation.previous ?
              <>
                <hr />
                <Row className='alert'>
                  <Col style={{'padding':'10px 0'}}>
                    Spostata in data<br />
                    <b>{ reservation.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.created_at.substr(11,5) : '-' }</b>
                  </Col>
                  <Col style={{'padding':'10px 0'}}>
                    Data e ora precedenti<br />
                    <b>{ reservation.previous.slot ? reservation.previous.slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+reservation.previous.slot.inizio.substr(11,5) : '-' }</b>
                  </Col>
                </Row>
              </>
            : '' }
            <hr />
            <h4>Dati pagamento</h4>
            <Row>
              <Col>
                Stato pagamento<br />
                <div className={'tag '+reservation.stato} style={{'display':'inline-block','margin':'0 5px 0 0'}}></div> <b>{ reservation.stato == 'approved' ? 'PAGATA' : (reservation.stato == 'suspended' ? 'NON PAGATA' : 'IN FASE DI CREAZIONE') }</b>
              </Col>
              <Col>
                Importo escursione<br />
                <b>{ reservation.pagamento_importo ? (parseInt(reservation.pagamento_importo) > 0 ? parseInt(reservation.pagamento_importo)+' €' : 'GRATIS') : '-' }</b>
              </Col>
              <Col>
                Data pagamento<br />
                <b>{ reservation.pagamento_data ? reservation.pagamento_data.substr(0, 10).split("-").reverse().join("/")+' '+reservation.pagamento_data.substr(11, 5) : '-' }</b>
              </Col>
              <Col>
                Tipo pagamento<br />
                <b>{ reservation.pagamento_tipo ? reservation.pagamento_tipo.toUpperCase() : '-' }</b>
              </Col>
              <Col>
                Buono regalo utilizzato<br />
                <b>{ reservation.voucher ? reservation.voucher : 'No' }</b>
              </Col>
            </Row>
            <hr />
            <h4>Dati partecipanti</h4>
            <Row style={{'marginTop':'-20px'}}>
              <Col className='tab_wrapper'>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Tipologia</th>
                      <th>Nome</th>
                      <th>Cognome</th>
                      <th>Età</th>
                    </tr>
                  </thead>
                  <tbody>
                    { reservation.clients.map((client) => 
                      <tr key={client.id}>
                        <td>{ client.client_type.descrizione.toUpperCase() }</td>
                        <td>{ client.nome }</td>
                        <td>{ client.cognome }</td>
                        <td>{ client.eta }</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col className='action_bar bottom' style={{'textAlign':'center'}}>
                { new Date() < new Date(reservation.slot.inizio) ?  // SOLO SE MI TROVO PRIMA DELLA DATA ESCURSIONE
                  reservation.stato == 'approved' ?   // PRENOTAZIONE PAGATA
                    <>
                      <Button onClick={() => { handleMoveReservation() }}><DynamicIcon iconName='FaCalendarAlt' /> Sposta prenotazione</Button>
                      <Button variant="danger" onClick={() => handleReservationDelete(reservation.id, reservation.titolare_email)}><DynamicIcon iconName='FaTrash' /> Annulla prenotazione</Button>
                    </>
                  : reservation.stato == 'suspended' && reservation.pagamento_importo ?  // PRENOTAZIONE NON PAGATA
                    <>
                      <Button onClick={() => { handlePayment(reservation.id, reservation.pagamento_importo) }}><DynamicIcon iconName='RiSecurePaymentFill' /> Paga prenotazione</Button>
                    </>
                  : ''
                : '' }
              </Col>
            </Row>
          </Row>
        </>
      : reservation && move ?
        <>
          <Row className='panel first' style={{'paddingBottom':'20px'}}>
            <Col>
              Codice prenotazione<br />
              <b>{ reservation.codice }</b>
            </Col>
            <Col>
              Tipologia escursione<br />
              <b>{ reservation.slot.slot_type.descrizione }</b>
            </Col>
            <Col>
              Num. partecipanti<br />
              <b>{ reservation.utenti.guidatori } guidatori - { reservation.utenti.passeggeri } passeggeri</b>
            </Col>
            <Col>
              Data e ora originali<br />
              <b>{ reservation.slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+reservation.slot.inizio.substr(11, 5) }</b>
            </Col>
            <Col>
              <Button onClick={() => { setMove(false) }}><DynamicIcon iconName='IoIosArrowBack' /> Torna al dettaglio</Button>
            </Col>
          </Row>
          <br />
          <Row className='panel'>
            <h4>Sposta la tua prenotazione</h4>
            <Row>
              <Col id='calendar_wrapper' style={{'marginTop':'25px'}}>
                <FullCalendar 
                  ref={calendarRef}
                  plugins={[ timeGridPlugin, interactionPlugin ]} 
                  locale={itLocale} 
                  initialView={isMobile ? 'timeGridDay' : 'timeGridWeek'}
                  eventClick={handleEventClick} 
                  datesSet={handleDatesSet}
                  events={slots} 
                  slotMinTime="08:00:00" 
                  slotMaxTime="20:00:00" 
                  allDaySlot={false} 
                  slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }} 
                  height='auto'
                />
              </Col>
            </Row>
          </Row>
        </>
      : '' }
    </Container>
  );
}

export default Manage;